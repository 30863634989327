@import url("https://fonts.googleapis.com/css2?family=Hind:wght@400;500;700&display=swap");
@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100..900;1,100..900&display=swap');

* {
  font-family: "Hind";
}

body {
  /* overflow-x: hidden; */
}

html {
  scrollbar-color: #686868 #424242;
  scrollbar-width: 10px;
}

.App {
  background-color: #121212;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  font-size: calc(10px + 2vmin);
  color: white;
  padding: 0 193px;
  /* width: 100vw; */
}

.gradient-circle {
  position: absolute;
  pointer-events: none;
  z-index: 100;
  width: 80%;
  opacity: .5;
  filter: blur(40px);
  height: 40px;
  background: radial-gradient(circle, #6bf882 0, hsla(64, 91%, 70%, .1) 100%);
}

.header div .mobile {
  display: none;
}

.header div .desktop {
  display: unset;
}


/**
* Media Queries/Responsiveness
*/
@media (max-width: 1440px) {

  /* Home */
  .App {
    padding: 0 110px;
  }

  .CardGroup {
    width: 110.4%;
  }

  /* Home */


  /* Subscribe */

  .subscribe-input-container {
    width: 50vw;
  }


  /* Subscribe */

}

@media (max-width: 1280px) {
  .App {
    padding: 0 80px;
  }

  .hero div h1 {
    font-size: 4.5rem;
  }

  .CardGroup {
    width: 108.4%;
  }
}

@media (max-width: 1024px) {

  .hero div h1 {
    font-size: 3.5rem;
  }
  .App {
    padding: 0 60px;
  }

  .CardGroup {
    width: 109.4%;
  }

    .subscribe-header {
      margin-bottom: 20px;
      margin-top: 60px;
    }
    .subscribe-disclaimer {
      bottom: -10vh;
  }
}

@media (max-width: 768px) {
  .hero{
    margin-top: 3rem;
    transition: 0.2s ease;
  }

  .hero div h1{
    text-align: center;
    font-size: 3rem;
  }

  .CardGroup {
    width: 130%;
    flex-direction: column;
  }

  .CardGroup a {
    margin-top: 20px;
  }

  .hero .links {
    justify-content: center;
    display: flex;
    margin-top: 60px;
  }

  .header {
    flex-direction: column;
  }

  .PickAChallenge h3:nth-child(1) {
    text-align: center;
  }

  .PickAChallenge {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .navbar_link {
    margin: 0 20px;
  }

  .header div .mobile {
    display: unset;
  }

  .header div .desktop {
    display: none;
  }

  /* Subscribe */
  .subscribe-header {
    margin-bottom: 0;
  }

  .contextual_message {
    width: calc(100vw - 40px);
  }

  .subscribe-input-container {
    width: 70vw;
  }
  /* Subscribe */

}

@media (max-width: 533px) {
  .App {
    padding: 0 20px;
  }

  .CardGroup {
    width: 107%;
  }
}
