.PickAChallenge {
    margin-top: 60px;
    padding: 0 20px;
}

h3 {
    margin: 0;
    font-size: 1.5rem;
    font-weight: lighter;
}

.CardGroup {
    display: flex;
    margin-top: 16px;
    margin-bottom: 100px;
    width: 114.4%;
    overflow-x: auto;
    scrollbar-color: #2a2a2a #121212;
    scrollbar-width: thin;
    padding: 10px 0;
    -webkit-overflow-scrolling: touch;
    scroll-snap-type: y mandatory;
}