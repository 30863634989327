.subscribe {
    width: 100%;
    height: 70vh;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

form {
    display: flex;
    flex-direction: column;
    align-items: center;

}

input[type="submit"] {
    margin-top: 40px;
    height: 50px;
    background: black;
    color: white;
    border: 1px white solid;
    border-radius: 10px;
    width: 120px;
    cursor: pointer;
    text-transform: capitalize;
    transition: 0.2s ease;
}

input[type="submit"]:hover {
    background: white;
    color: black;
    transition: 0.2s ease;
}

.subscribe h4 {
    margin: 0;
    font-weight: lighter;
    text-align: center;
}

.subscribe h4,
.subscribe input,
.subscribe button,
.subscribe p,
.subscribe-input-container {
    animation-name: onLoad;
    animation-duration: 1.5s;
}

@keyframes onLoad {
    from {
        opacity: 0;
        transform: translateY(-20px);
    }

    to {
        opacity: 1;
        transform: unset;
    }
}


.subscribe-header p {
    font-size: 1rem;
    color: rgb(173, 173, 173);
    margin: 0;
}

.subscribe-header {
    margin-bottom: 100px;
}

.subscribe-input-container {
    background: rgb(209, 209, 209);
    width: 500px;
    height: 30px;
    padding: 10px;
    display: flex;
    justify-content: space-between;
    border-radius: 10px;

    input {
        background: rgb(209, 209, 209);
        border: none;
        font-size: 16px;
        width: 100%;
        margin: 0;
        outline: none;
        text-align: center;
    }

    a {
        background-color: black;
        color: white;
        height: 100%;
        outline: none;
        width: 20%;
        border: none;
    }
}

.contextual_message{
    width: calc(100vw/3);
}

.subscribe-disclaimer {
    font-size: .85rem;
    color: rgb(160, 160, 160);
    position: relative;
    bottom: -15vh;
    text-align: center;
}

/* Loading spinner */
.loader {
    transform: translateY(100px);
    width: 10px;
    height: 10px;
    border-radius: 50%;
    display: block;
    margin: 15px auto;
    position: relative;
    color: #FFF;
    left: -100px;
    box-sizing: border-box;
    animation: shadowRolling 3s linear infinite;
}

@keyframes shadowRolling {
    0% {
        box-shadow: 0px 0 rgba(255, 255, 255, 0), 0px 0 rgba(255, 255, 255, 0), 0px 0 rgba(255, 255, 255, 0), 0px 0 rgba(255, 255, 255, 0);
    }

    12% {
        box-shadow: 100px 0 white, 0px 0 rgba(255, 255, 255, 0), 0px 0 rgba(255, 255, 255, 0), 0px 0 rgba(255, 255, 255, 0);
    }

    25% {
        box-shadow: 110px 0 white, 100px 0 white, 0px 0 rgba(255, 255, 255, 0), 0px 0 rgba(255, 255, 255, 0);
    }

    36% {
        box-shadow: 120px 0 white, 110px 0 white, 100px 0 white, 0px 0 rgba(255, 255, 255, 0);
    }

    50% {
        box-shadow: 130px 0 white, 120px 0 white, 110px 0 white, 100px 0 white;
    }

    62% {
        box-shadow: 200px 0 rgba(255, 255, 255, 0), 130px 0 white, 120px 0 white, 110px 0 white;
    }

    75% {
        box-shadow: 200px 0 rgba(255, 255, 255, 0), 200px 0 rgba(255, 255, 255, 0), 130px 0 white, 120px 0 white;
    }

    87% {
        box-shadow: 200px 0 rgba(255, 255, 255, 0), 200px 0 rgba(255, 255, 255, 0), 200px 0 rgba(255, 255, 255, 0), 130px 0 white;
    }

    100% {
        box-shadow: 200px 0 rgba(255, 255, 255, 0), 200px 0 rgba(255, 255, 255, 0), 200px 0 rgba(255, 255, 255, 0), 200px 0 rgba(255, 255, 255, 0);
    }
}

.subscribe-success-message{
    font-size: 1.2rem;
    transform: translateY(-55px);
    width: 400px;
    text-align: center;
    color: lightgreen;
    font-weight: 100;
}

/* Confirmation animation */

.wrapper {
    height: 15vh;
    display: flex;
    justify-content: center;
    align-items: center;
    /* background-color: #eee */
}

.checkmark__circle {
    stroke-dasharray: 166;
    stroke-dashoffset: 166;
    stroke-width: 2;
    stroke-miterlimit: 10;
    stroke: #7ac142;
    fill: none;
    animation: stroke 0.6s cubic-bezier(0.65, 0, 0.45, 1) forwards
}

.checkmark {
    width: 56px;
    height: 56px;
    border-radius: 50%;
    display: block;
    stroke-width: 2;
    stroke: #fff;
    stroke-miterlimit: 10;
    margin: 10% auto;
    box-shadow: inset 0px 0px 0px #7ac142;
    animation: fill .4s ease-in-out .4s forwards, scale .3s ease-in-out .9s both
}

.checkmark__check {
    transform-origin: 50% 50%;
    stroke-dasharray: 48;
    stroke-dashoffset: 48;
    animation: stroke 0.3s cubic-bezier(0.65, 0, 0.45, 1) 0.8s forwards
}

@keyframes stroke {
    100% {
        stroke-dashoffset: 0
    }
}

@keyframes scale {

    0%,
    100% {
        transform: none
    }

    50% {
        transform: scale3d(1.1, 1.1, 1)
    }
}

@keyframes fill {
    100% {
        box-shadow: inset 0px 0px 0px 30px #7ac142
    }
}