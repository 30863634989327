.error-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    h1 {
        font-size: 775%;
        margin-bottom: 1vh;
        z-index: 2;
    }
    p { font-size: 125%; }
    a {
        text-decoration: none;
        color: white;
        z-index: 2;
    }
    a:hover { text-decoration: underline; }

    a, h1, p {
        animation: opacityLetters 4s;
    }
}

.travolta {
    z-index: 5;
    width: 100vw;
    height: 100vh;
    opacity: 0.3;
    position: absolute;
    top: 0;
    left: 0;
    background: url('https://assets-9gag-fun.9cache.com/s/fab0aa49/033037194a549b0bf83e2ac4ba90706a52a9132e/static/dist/web6/img/404/bg.gif') center center no-repeat;
    background-size: cover;
    background-color: black;
    animation: opacityBg 1s;
}

@keyframes opacityBg {
    from { opacity: 0; }
    to { opacity: 0.3; }
}

@keyframes opacityLetters {
    from { opacity: 0; padding: -10px 0;}
    to { opacity: 1; padding: unset;}
}

.header {
    position: relative;
    z-index: 10;
}