.hero h1 {
    font-size: 6rem;
    padding: .5rem 0;
    font-weight: lighter;
    margin: 0;
    animation: opacityMovement 1s ease-out;
    text-align: left;
}

@keyframes opacityMovement{
    from{ opacity: 0; transform: translateY(-20px); }
    to{ opacity: 1; transform: unset}
}

.hero{
    margin-top: 5rem;
    padding: 0 20px;
}

.hero a{
    text-decoration: none;
    color: white;
    margin-right: 18px;
    font-size: 1rem;
    transition: 0.2s ease;
    padding: 5px 10px;
    border-radius: 10px;
}

.links{
    margin-top: 100px;
    transition: 0.2s ease;

}

.links a:hover{
    background: white;
    color: black;
    transition: 0.2s ease;
}
