.header{
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 20px;
    font-size: 1rem;
    margin-top: 1.7rem;
    a{
        text-decoration: none;
        color: white;
    }
}

.navbar_link{
    color: white;
    margin-left: 32px;
    cursor: pointer;
}

.header h1{
    font-size: 1rem;
    font-weight: 500;
}

/* Header collapseable menu */

.navbar {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 1rem 1.5rem;
    width: 100%;
}

.hamburger {
    display: none;
}

.bar {
    display: block;
    width: 20px;
    height: 2px;
    margin: 5px auto;
    transition: all 0.3s ease-in-out;
    background-color: white;
}

.nav-menu {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.nav-item {
    margin-left: 2rem;
}

header .nav-link{
    font-size: 1rem;
    font-weight: 400;
    color: white;
}

.nav-link:hover{
    color: rgb(207, 207, 207);
}

.nav-logo {
    font-size: 1rem;
    font-weight: 500;
    color: white;
}

@media only screen and (max-width: 768px) {

    header .nav-link {
        font-size: 1.3rem;
        color: white;
    }
    
    .nav-menu {
        margin: 0 10px;
        padding: 0;
        position: fixed;
        z-index: 10;
        left: -100%;
        top: 5.5rem;
        flex-direction: column;
        background: radial-gradient(circle at 10% 20%, rgb(17 23 20) 0%, rgb(0 0 0) 90.1%);
        width: calc(100% - 20px);
        border-radius: 10px;
        text-align: center;
        transition: 0.3s;
        box-shadow:
            0 10px 27px rgba(0, 0, 0, 0.05);
    }

    .nav-menu.active {
        left: 0;
    }

    .nav-item {
        margin: 2.5rem 0;
    }

    .hamburger {
        display: block;
        cursor: pointer;
    }

    .hamburger.active .bar:nth-child(2) {
        opacity: 0;
    }

    .hamburger.active .bar:nth-child(1) {
        transform: translateY(8px) rotate(45deg);
    }

    .hamburger.active .bar:nth-child(3) {
        transform: translateY(-8px) rotate(-45deg);
    }

}

header li {
    list-style: none;
}

header a {
    text-decoration: none;
}