.ChallengeCard {
    height: 468px;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    padding: 30px;
    margin-right: 13px;
    text-decoration: none;
    color: white;
    position: relative;
    background-size: cover !important;
    background-position: center !important;
    transition: 0.2s ease;
    animation: animateCardOnLoad 1s ease;
}

@keyframes animateCardOnLoad{
    from{ opacity: 0; }
    to { opacity: 1; }
}

.ChallengeCard:hover{
    transform: scale(1.02);
    transition: 0.2s ease;
}

.ChallengeCard[data-ribbon='Coming soon'] {
    filter: brightness(0.4) grayscale(1);
}

.ChallengeCard h4 {
    width: 235px;
    font-size: 1.5rem;
    font-weight: lighter;
    margin-bottom: 0;
}

.ChallengeCard p {
    font-size: 1rem;
    font-weight: lighter;
}

a[data-ribbon="Coming soon"] {
    cursor: default;
}


/* Ribbon */

.mk-wrap {
    transition: box-shadow .2s ease-in-out 0;
    box-shadow: 0 2px 10px 0 rgba(0, 0, 0, .16), 0 2px 5px 0 rgba(0, 0, 0, .26)
}

.mk-wrap[data-ribbon]:after,
.mk-wrap[data-ribbon]:before {
    content: '';
    right: -5px;
    position: absolute
}

.mk-wrap[data-ribbon]:before {
    top: 15px;
    z-index: 1;
    padding: 5px;
    border: 1px solid;
    font-size: 16px;
    font-weight: 400;
    content: attr(data-ribbon)
}

.mk-wrap[data-ribbon='15 days']:before {
    background: orange;
    color: white;
    border-color: orange;
}

.mk-wrap[data-ribbon='7 days']:before {
    background: lightgreen;
    color: black;
}

.mk-wrap[data-ribbon='30 days']:before {
    background: red;
    color: white;
    border-color: red;
}

.mk-wrap[data-ribbon='Coming soon']:before {
    background: #ccc;
    color: rgb(57, 57, 57);
    border-color: #ccc;
}


.mk-wrap[data-ribbon] {
    position: relative
}

.mk-wrap[data-ribbon][data-ribbon-style=banner] {
    overflow: hidden
}

.mk-wrap[data-ribbon][data-ribbon-style=banner]:before {
    top: 15px;
    right: -30px;
    overflow: hidden;
    padding: 5px 30px;
    text-align: center;
    white-space: nowrap;
    text-overflow: ellipsis;
    -webkit-transform: rotate(40deg);
    -moz-transform: rotate(40deg);
    transform: rotate(40deg)
}