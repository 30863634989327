footer{
    display: flex;
    justify-content: center;
    padding-bottom: 25px;

    p{
        font-size: 1rem;
    }
    a{
        text-decoration: none;
        color: rgb(117, 203, 114);
    }
}